@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url('../fonts/barlow-condensed-regular.woff2') format('woff2'),
    url('../fonts/barlow-condensed-regular.woff') format('woff'),
    url('../fonts/barlow-condensed-regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Barlow Condensed';
    font-style: normal;
    font-weight: 700;
    src: local(''),
    url('../fonts/barlow-condensed-700.woff2') format('woff2'),
    url('../fonts/barlow-condensed-700.woff') format('woff'),
    url('../fonts/barlow-condensed-700.ttf') format('truetype');
}
:root {
    --donpepe-red: #ed1c24;
}
*,::after,::before {
    box-sizing: border-box
}
html { font-size: 20px; height: -webkit-fill-available; }
body { position: relative; font-family: 'Barlow Condensed', sans-serif; font-size: 1rem; margin: 0; padding: 0; -webkit-text-size-adjust: 100%; -webkit-tap-highlight-color: transparent;
    display: flex; align-items: center; justify-content: center;
    min-height: 100vh;
    min-height: -webkit-fill-available;
}

.btn { display: inline-flex; align-items: center; font-weight: 700; line-height: 1.5; color: #fff; text-align: center; text-decoration: none; text-transform: uppercase;
    vertical-align: middle; cursor: pointer; -webkit-user-select: none; -moz-user-select: none; user-select: none; background-color: var(--donpepe-red); border: 4px solid var(--donpepe-red);
    padding: 0.75rem 1rem; font-size: 1rem; border-radius: 50rem; transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}
.btn>svg { width: 13px; height: 13px; margin-left: 1rem; }
.btn:hover { background-color: #000; color: #fff; border-color: #000; }

.mx-auto { margin-left: auto; margin-right: auto; }
.d-block { display: block; }
.img-fluid { max-width: 100%; height: auto; }

.wrapper {  }

.error-box { text-align: center; /*flex: 1;*/ padding: 1rem 2rem; }
.error-box .logo { display: inline-block; margin-bottom: 1rem; color: var(--donpepe-red); }
.error-box .logo svg { width: 120px; }
.error-box .logo svg .logo-title { display: none; }
.error-box h1 { margin: 0 0 0 0;}
.error-box h1 span { display: block; position: absolute; font-size: 0; top: -999rem; left: -999rem; width: 0; height: 0; overflow: hidden; line-height: 0; }
.error-box h2 { margin: 0 0 0 0; font-size: 3rem; font-weight: 700; }
.error-box h2 span { display: block; font-weight: 400; font-size: 1.75rem; }
.error-box .button-wrapper { margin-top: 2rem; }

@media (max-width: 575.98px) {
    html { font-size: 14px; }

}
@media (max-width: 767.98px) {
    html { font-size: 16px; }
}
